<template>
  <div class="record">
    <Owned mark="Record" />

    <van-tabs class="tabs" type="card" @change="onChange">
      <van-tab title="闪出" name="2" />
      <van-tab title="开袋" name="1" />
      <van-tab title="获得" name="3" />
      <van-empty v-if="!bills || bills.length < 1" description="暂无数据" />
      <van-pull-refresh v-model="pullLoading" @refresh="refresh">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="getBill"
          class="bill-list"
        >
          <van-cell
            span="12"
            v-for="(bill, i) in bills"
            :key="i"
            center
            :title="bill.remark"
            :label="bill.created_at | formatDate"
          >
            <template #default>
              <div v-if="type == 1">
                <span v-if="bill.cash < 0" class="low block">
                  -<i class="icon-rmb" />{{ String(bill.cash).slice(1) }}
                </span>
                <span v-if="bill.coin < 0" class="low block">
                  -<i class="icon-coin" />{{ String(bill.coin).slice(1) }}
                </span>
              </div>
              <div v-else>
                <span class="up">
                  +<i class="icon-coin" />{{ bill.coin }}
                </span>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </van-tabs>
  </div>
</template>

<script>
import Owned from "@/components/Owned.vue";
export default {
  name: "Record",
  components: { Owned },
  data() {
    return {
      type: 2, //1.开袋（开启盲盒） 2.闪出（闪出盒柜） 3.获得（任务奖励）
      page: 0,
      bills: null,
      loading: false,
      finished: false,
      pullLoading: false,
    };
  },
  created() {},
  methods: {
    onChange(type) {
      this.type = type;
      this.page = 0;
      this.getBill();
    },
    refresh() {
      this.page = 0;
      this.getBill();
    },
    getBill() {
      this.$ajax
        .post("/user/coin/bills/", {
          channel: this.type,
          page_no: this.page,
          page_size: 10,
        })
        .then((res) => {
          if (this.page === 0) {
            this.bills = res.data;
          } else {
            if (res.data) {
              this.bills = [...this.bills, ...res.data];
            }
          }
          if (res.next_page < 0) {
            this.finished = true;
          } else {
            this.page = res.next_page;
            this.finished = false;
          }
          this.loading = false;
          this.pullLoading = false;
        });
    },
  },
};
</script>
